<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">Yearly Schedule Plan</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive">
              <table class="table table-striped dt-responsive w-100 mb-3" id="yearly-plan-table" v-show="!loading">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>From => To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(budgetYear, index) in budgetYears" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ budgetYear.name }}</td>
                    <td>
                      {{ budgetYear.date_from + " => " + budgetYear.date_to }}
                    </td>
                    <td>
                      <badgeSuccess :name="'Active'" v-if="budgetYear.date_from <= current_year &&
                        budgetYear.date_to >= current_year
                        " />
                      <badgeInfo :name="'Upcoming'" v-else-if="budgetYear.date_from > current_year" />
                      <badgeDanger :name="'Inactive'" v-else />
                    </td>
                    <td>
                      <router-link :to="{
                        name: 'yearly-training-plan-show',
                        params: { id: budgetYear.id },
                      }">
                        <button type="button" class="
                                      btn btn-xs btn-blue
                                      waves-effect waves-light
                                      mb-2
                                    ">
                          <i class="mdi mdi-plus-circle"></i>
                          Add/View Yearly Training Plan
                        </button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";
import badgeInfo from "../../components/shared/badgeInfo.vue";
import moment from "moment";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    badgeInfo,
  },
  data() {
    return {
      budgetYears: [],
      current_year: "",
      previous_year: "",
      current_year_name: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllBudgetYears() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.budgetYears = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
      $("#yearly-plan-table").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#yearly-plan-table").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllBudgetYears();
    this.current_year = moment().format("YYYY-MM-DD");
    this.previous_year = this.current_year - 1;
    this.current_year_name = this.previous_year + "-" + this.current_year;
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

</style>